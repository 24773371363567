
/**
 * @name: 视频课程订单
 * @author: itmobai
 * @date: 2023-06-01 14:53
 * @description：视频课程订单
 * @update: 2023-06-01 14:53
 */
import {Component, Vue} from "vue-property-decorator";
import {ICrudOption} from "@/types/m-ui-crud";
import {deepCopy, exportFile} from "@/utils/common";
import {orderQueryVideoOrderByPageApi, orderVideoOrderExportApi} from "@/apis/order/lesson";
import {ILessonOrder, ILessonOrderParam} from "@/apis/order/lesson/types";

@Component({})
export default class LessonOrderPage extends Vue {
// 表格加载状态
  tableLoading: boolean = false;
  // 表格总数
  tableTotal: number = 0;
  // 表格数据
  tableData: ILessonOrder[] = []
  // 表单
  modelForm: Partial<ILessonOrder> = {}
  // 查询参数
  queryParam: ILessonOrderParam = {
    page: 1,
    limit: 10
  }
  // crudOption
  option: ICrudOption = {
    labelWidth: '120px',
    menu: false,
    column: [
      {
        "label": "订单编号",
        "prop": "orderSn",
        "align": "left",
        "width": "180",
        "search": true
      },
      {
        "label": "视频课程名称",
        "prop": "videoCourseName",
        "align": "center",
        "search": true,
        "overHidden": true
      },
      {
        "label": "下单客户",
        "prop": "orderCustom",
        "align": "center",
        "width": "150",
        "overHidden": true,
        "search": true
      },
      {
        "label": "订单金额",
        "prop": "orderPrice",
        "align": "center",
        "width": "150"
      },
      {
        "label": "订单积分",
        "prop": "integral",
        "align": "center",
        "width": "150"
      },
      {
        "label": "支付方式",
        "prop": "payWay",
        "align": "center",
        "type": "select",
        "width": "150",
        "search": true,
        "dicData": [
          {
            "label": "余额",
            "value": 1
          },
          {
            "label": "微信支付",
            "value": 2
          }
        ]
      },
      {
        "label": "订单状态",
        "prop": "courseOrderStatus",
        "align": "center",
        "width": "150",
        "type": "select",
        "search": true,
        "dicData": [
          {
            "label": "待支付",
            "value": 1,
            "color": "#F56C6C"
          },
          {
            "label": "待使用",
            "value": 2,
            "color": "#409EFF"
          },
          {
            "label": "使用中",
            "value": 3,
            "color": "#409EFF"
          },
          {
            "label": "已完成",
            "value": 4,
            "color": "#67C23A"
          },
          {
            "label": "已取消",
            "value": 5,
            "color": "#F56C6C"
          }
        ]
      },
      {
        "label": "下单时间",
        "prop": "payTime",
        "align": "center",
        "width": "180",
        "type": "daterange",
        "search": true
      }
    ]
  }

  /**
   * 导出excel
   */
  exportExcel () {
    const query: ILessonOrderParam = deepCopy(this.queryParam)
    if (query.payTime && query.payTime.length === 2) {
      query.payTimeStart = query.payTime[0]
      query.payTimeEnd = query.payTime[1]
    } else {
      query.payTimeStart = ""
      query.payTimeEnd = ""
    }
    delete query.payTime
    orderVideoOrderExportApi(query).then(e => {
      exportFile(e, '视频课程订单.xlsx')
    })
  }

  /**
   * 查询
   */
  getList () {
    this.tableLoading = true
    const query: ILessonOrderParam = deepCopy(this.queryParam)
    if (query.payTime && query.payTime.length === 2) {
      query.payTimeStart = query.payTime[0]
      query.payTimeEnd = query.payTime[1]
    } else {
      query.payTimeStart = ""
      query.payTimeEnd = ""
    }
    delete query.payTime
    orderQueryVideoOrderByPageApi(query).then(e => {
      this.tableLoading = false
      this.tableData = e.list
      this.tableTotal = e.total
    })
  }

  created () {
    this.getList()
  }
}
