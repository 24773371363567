/**
 * @name: 视频课程订单接口
 * @author: itmobai
 * @date: 2023-06-01 15:51
 * @description：视频课程订单接口
 * @update: 2023-06-01 15:51
 */
import {get} from "@/request";
import {ILessonOrder, ILessonOrderParam} from "@/apis/order/lesson/types";
import {IPageRes} from "@/apis/page";

/**
 * 视频课程分页查询
 * @param params 查询参数
 */
export const orderQueryVideoOrderByPageApi = (params: ILessonOrderParam) => get<IPageRes<ILessonOrder[]>>("/golf/order/queryVideoOrderByPage", params)
/**
 * 视频课程导出
 * @param params 查询参数
 */
export const orderVideoOrderExportApi = (params: ILessonOrderParam) => get("/golf/order/videoOrderExport", params, 'blob')
